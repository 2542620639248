import React, { useEffect, useMemo } from 'react';
import { scrollIntoView } from '@helpers/dom';
import { getOrigin, getSupportEmail } from '@helpers/url';
import { ROUTES } from '@routing/routes';
import { AppFC } from '@types';
import { TextPageLinkExternalStyled, TextPageLinksStyled, TextPageMarkerStyled, TextPageOrderedListItemStyled, TextPageOrderedListStyled, TextPageSectionStyled, TextPageSectionTitleStyled, TextPageStyled, TextPageSubtitleStyled, TextPageTitleStyled } from '@kit/text-page';
import { TERM_FOR_EU_RESIDENT } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const TermsForEuResidentsScene: AppFC = () => {
  const origin = useMemo(() => getOrigin(), []);
  const supportEmail = useMemo(() => getSupportEmail(), []);
  const rightOfWithdrawalUrl = useMemo(() => `${origin}${ROUTES.rightOfWithdrawalForEuResidents.index.getUrl()}`, [origin]);
  const privacyPolicyEnUrl = useMemo(() => `${origin}${ROUTES.privacyPolicy.en.index.getUrl()}`, [origin]);
  useEffect(() => {
    const hash = window.location.hash.slice(1);
    if (hash === TERM_FOR_EU_RESIDENT.NOTIFICATION_OF_ILLEGALITY) {
      const element = document.getElementById(TERM_FOR_EU_RESIDENT.NOTIFICATION_OF_ILLEGALITY);
      if (!element) {
        return;
      }
      scrollIntoView(element, {
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, []);
  return _jsxs(TextPageStyled, {
    children: [_jsx(TextPageLinksStyled, {
      children: _jsx(TextPageLinkExternalStyled, {
        href: "/images/T&C_for_EU_Residents.pdf",
        download: "T&C_for_EU_Residents",
        children: "Download PDF"
      })
    }), _jsx(TextPageTitleStyled, {
      children: "Rover Media Ltd\u2019 Freuders.com - Terms"
    }), _jsx(TextPageSubtitleStyled, {
      children: "for agreements concluded with online consumers in the EU through the Website version of 20 January 2025"
    }), _jsxs(TextPageSectionStyled, {
      children: [_jsx(TextPageSectionTitleStyled, {
        children: "1. Application"
      }), _jsxs(TextPageOrderedListStyled, {
        children: [_jsxs(TextPageOrderedListItemStyled, {
          children: ["1.1. These general terms and conditions (as amended from time to time) (the ", _jsx("strong", {
            children: "Terms"
          }), ") are used by Rover Media Ltd., registered at 4 Ariel Sharon Givatayim, 14th floor, in (5320047) Israel (", _jsx("strong", {
            children: "\u201CRover\u201D"
          }), " or ", _jsx("strong", {
            children: "\u201Cwe\u201D"
          }), " or", ' ', _jsx("strong", {
            children: "\u201Cus\u201D"
          }), "). Rover\u2019s local Israeli vat number is 516214392. Any questions, requests or complaints you have may be addressed to Rover either per e-mail to", ' ', _jsx(TextPageLinkExternalStyled, {
            href: `mailto:${supportEmail}`,
            children: supportEmail
          }), ' ', "or per telephone by dialling", ' ', _jsx(TextPageMarkerStyled, {
            children: "+972-3-5277233"
          }), "."]
        }), _jsxs(TextPageOrderedListItemStyled, {
          children: ["1.2. These Terms shall be applicable to and constitute part of every online agreement entered into between Rover and a consumer (the", ' ', _jsx("strong", {
            children: "\u201CConsumer\u201D"
          }), " or ", _jsx("strong", {
            children: "\u201Cyou\u201D"
          }), ") residing in the EU. When these Terms speak of an online agreement (or", ' ', _jsx("strong", {
            children: "\u201CAgreement\u201D"
          }), "), it refers to the electronic contract entered into by a Consumer with us through his or her completion of the necessary registration steps on the website freuders.com (the", ' ', _jsx("strong", {
            children: "\u201CAgreement\u201D"
          }), "). The term \u2018Consumer\u2019 in these Terms refers to any natural person who seeks to enter into a legal relationship with Rover for a purpose which can be regarded as being outside of his or her trade or profession."]
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "1.3. These Terms are made available to Consumers and presented to them for acceptance prior to the Consumer concluding any online purchase on the Website and in a manner which allows the Consumer to download a pdf. file of the Terms and store this file on a durable medium."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "1.4. The Website exclusively targets Russian speaking consumers in countries - outside of Russia -to which the Website directs its activities. The Website does not direct any activities to Russia itself. Due to this exclusive focus on the niche market of Russian speaking consumers, the Website shall, subject to mandatory language requirements in a specific jurisdiction, be available in the Russian language only. Consumers who do not master the Russian language should not purchase any services on the Website."
        })]
      })]
    }), _jsxs(TextPageSectionStyled, {
      children: [_jsx(TextPageSectionTitleStyled, {
        children: "2. Payment collection by European entities"
      }), _jsxs(TextPageOrderedListStyled, {
        children: [_jsx(TextPageOrderedListItemStyled, {
          children: "2.1. When you register on the Website to receive the services offered thereon, you shall enter into an online agreement with Rover. Should you proceed to purchase any services on the Website, you shall be invoiced for the services by Rover. Any complaints you may have regarding (inter alia) the services or payments can be directed towards Rover through the contact channels set out in clause 1.1. Rover shall then proceed to handle your complaints."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "2.2. For purposes of efficient payment processing and collection on behalf of Rover, Rover uses its affiliated entities in the Netherlands or Finland (which are under similar ownership as Rover) for the collection of payments from Consumers who purchase services on the Website. The Dutch affiliated entity is called Interactive Online Technologies B.V. and it is registered at Kingsfordweg 151 in (1043 GR) Amsterdam, the Netherlands. The Finnish affiliated entity is called Interactive Online Technologies Oy and is registered at Teknobulevardi 2-5 in (01530 Vantaa) Finland."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "2.3. Though you will be invoiced by Rover in Israel for any of your purchases on the Website, your payments will, pursuant to the intermediary services of payment service providers, be collected by either Interactive Online Technologies B.V. or Interactive Online Technologies Oy for subsequent remittance to Rover in Israel."
        })]
      })]
    }), _jsxs(TextPageSectionStyled, {
      children: [_jsx(TextPageSectionTitleStyled, {
        children: "3. Enjoying the Website"
      }), _jsxs(TextPageOrderedListStyled, {
        children: [_jsx(TextPageOrderedListItemStyled, {
          children: "3.1. This section will set out the nature of the product offered on the Website."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "3.2. Upon accessing the Website, you will be requested to create an account by providing an e-mail address and password. Alternatively, you can sign up through Google or Meta (formerly Facebook). Once you have successfully created an account, you will have access to your own personal environment and dashboard where you can see the status of your purchased items. The registration process is explained in detail on the Website pages."
        }), _jsxs(TextPageOrderedListItemStyled, {
          children: ["3.3. The Website offers Consumers a platform through which they can communicate with third party service providers (our", ' ', _jsx("strong", {
            children: "\u201CMindCare Providers\u201D"
          }), ") for obtaining mental (mind-related) and emotional care, guidance and advice (hereafter defined as: \u201Cthe ", _jsx("strong", {
            children: "Support"
          }), "\u201D). This means that as a Consumer you can register on the Website and expect to communicate with MindCare Providers about issues and questions you are wrestling with and you would like to get some guidance on from providers who love to spend their time helping folks like yourself feel better, more joyful and confident.", _jsx("br", {}), _jsx("br", {}), _jsx("i", {
            children: "Our MindCare Providers"
          })]
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "3.4. All our MindCare Providers are people who enjoy devoting their time to the wellbeing of others. They have different qualifications for doing so. Some of our MindCare Providers are psychologists with completed degrees in psychology studies. Some are therapists or coaches who have other type of training in the art of helping people get to a better and more happy mind state. Yet others do not have any formal training at all but are passionate about helping people improve their lives by listening to them and providing them with mental and emotional support and guidance. In short, our MindCare Providers have different profiles but they are all driven by a wish to help others feel better."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "3.5. Once you have logged into our platform, you will be presented with the profiles of the available MindCare Providers. You should take your time and study the profiles, their background and qualifications in order to make an informed decision on a MindCare Provider who best serves your wishes and needs. Once you have selected your MindCare Provider, you can decide to communicate either via messages, audio or even video."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "3.6. In case of MindCare Providers who present themselves as psychologists, Rover will require them to submit documentation (degrees/diploma\u2019s/certificates) which serves as proof of an obtained degree in psychology. Though Rover will perform a preliminary review of submitted documentation underlying a psychology degree, Rover does not guarantee the accuracy and validity of any such documentation. The Consumer herewith agrees that Rover may not be held liable in case a MindCare Provider\u2019s submitted documentation does not validly justify the qualifications which the MindCare Provider claims to possess. In case of MindCare Providers other than psychologists, Rover does not perform any review of documentation they may have to underly their presented qualifications."
        })]
      })]
    }), _jsxs(TextPageSectionStyled, {
      children: [_jsx(TextPageSectionTitleStyled, {
        children: "4. No Medical Services"
      }), _jsxs(TextPageOrderedListStyled, {
        children: [_jsx(TextPageOrderedListItemStyled, {
          children: "4.1. Our MindCare Providers shall not use our platform to provide \u2018medical services\u2019 and therefore are generally not registered as medical practitioners in the Consumer\u2019s country of residence. Instead of medical services, our MindCare Providers will provide general mental and emotional care and guidance to Consumers in an effort to help them improve their mental and emotional wellbeing."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "4.2. If you are in need of urgent or non-urgent medical aid (including for any feelings you may have to inflict (suicidal) harm on yourself) you should not use the Rover platform and the services of our MindCare Providers. Instead, you should call your local hospital or medical practitioner. Our platform does not facilitate the provision of medical services, i.e. the establishment of a diagnosis and subsequent determination of specific medical treatment. Instead, it enables Consumers to seek general support and guidance with mental and emotional issues which they may experience and for which they would like to converse with a MindCare Provider in an effort to feel better."
        })]
      })]
    }), _jsxs(TextPageSectionStyled, {
      children: [_jsx(TextPageSectionTitleStyled, {
        children: "5. The Online Purchasing Process"
      }), _jsxs(TextPageOrderedListStyled, {
        children: [_jsx(TextPageOrderedListItemStyled, {
          children: "5.1. The online purchasing process is explained in detail on the Website as you go through the steps. In these Terms, we will explain the basics."
        }), _jsxs(TextPageOrderedListItemStyled, {
          children: ["5.2. During the online purchasing process on the Website, the Consumer shall be presented in a clear and conspicuous manner with the mandatory information pursuant to the laws applicable to the conclusion of online agreements. This information shall inter alia include the main characteristics of the offers as well as their price.", _jsx("br", {}), _jsx("br", {}), _jsx("i", {
            children: "Purchasing coins for communicating"
          })]
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "5.3. Rover uses a so-called freemium model, which means that Consumers can sign up for free and get limited access to the Website\u2019s features. As such, every Consumer who signs up will be able to send two free messages to a MindCare Provider. Once you are out of free messages but wish to continue enjoying the Website, you will be asked to purchase coins. The Website will show you the available payment methods."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "5.4. Every coin equals one message of a limited number of symbols or one minute of audio or video communications. Once you run out of coins but would like to continue enjoying the Website some more, you will have to purchase new ones. The coins are offered in different pricing packages which are presented and explained on the Website. Your personal environment withing your account lists your remaining coins. You pay as you go. Alternatively, you can also purchase a subscription whereby you will be charged a monthly fee. The upside of a subscription plan is that you will get a 10% discount on the price."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "6. The length of the messages that can be sent per purchased coin or the length of time per coin that can be communicated per audio or video is all set by Rover. Should Rover wish to change the price of a coin or said lengths of messages or other communications, you will be informed of such pending change within a reasonable period in advance to allow you to consider whether you wish to continue your online agreement with Rover or to terminate the same. Under no circumstance will any alteration be made to the scope of your communication rights attached to coins which you have already purchased."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "7. Purchased coins have no monetary value, i.e. they are not equal to money and cannot be used anywhere other than on the Website in exchange for the ability to communicate with our MindCare Providers. Furthermore, purchased coins are not transferable."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "7.1. Prior to placing an online purchase for coins, you shall be able (at all times) to review the purchased items and make adjustments if you so wish. To view and adjust the purchase order (or delete the purchase order altogether) the Consumer can use the \u2018back\u2019 button to surf back to the previous page and make the adjustments"
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "7.2. Shortly after purchase, the Consumer shall be sent an email confirming the receipt of the online order as well as further necessary information"
        })]
      })]
    }), _jsxs(TextPageSectionStyled, {
      children: [_jsx(TextPageSectionTitleStyled, {
        children: "8. Pricing and payments"
      }), _jsx(TextPageOrderedListStyled, {
        children: _jsx(TextPageOrderedListItemStyled, {
          children: "8.1. The final prices communicated to Consumers on the Website are always inclusive of any owed VAT."
        })
      })]
    }), _jsxs(TextPageSectionStyled, {
      children: [_jsx(TextPageSectionTitleStyled, {
        children: "9. Prohibitions"
      }), _jsxs(TextPageOrderedListStyled, {
        children: [_jsx(TextPageOrderedListItemStyled, {
          children: "9.1. Consumers are obliged to treat our MindCare Providers with respect and refrain from the use of any language, media or actions which may cause unease, offence, fear, an infringement of anyone\u2019s right, a breach of any law or harm to our MindCare Providers."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "9.2. All intellectual property on or relating to the Website belongs to (or is licensed to) Rover. Consumers must refrain from using any intellectual property on the Website for any purpose other than the use and enjoyment of the services provided by Rover."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "9.3. Consumers must refrain from any use of the Website (or any content thereon) for any purpose other than consuming the services offered by Rover and shall thus refrain from any use which may have a commercial purpose or otherwise fall within a trade or profession."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "9.4. Consumers are prohibited from circumventing Rover\u2019s platform by establishing direct contact with the MindCare Providers in an effort to receive the Support outside of the Rover platform. Should Rover identify a violation of this prohibition, it shall hold the Consumer liable for Rover\u2019s damages. The damages shall be equal to the average daily (euro) spent of a Consumer multiplied by the number of days during which the circumvention has taken place."
        })]
      })]
    }), _jsxs(TextPageSectionStyled, {
      children: [_jsx(TextPageSectionTitleStyled, {
        children: "10. Liability"
      }), _jsxs(TextPageOrderedListStyled, {
        children: [_jsx(TextPageOrderedListItemStyled, {
          children: "10.1. The Consumer acknowledges and agrees that Rover\u2019s business consists of merely an intermediary service offering a platform which helps Consumers to gain access to MindCare Providers with whom they can converse."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "10.2. MindCare Providers are third party service providers. They are not employed by Rover and act for their own account and risk. The Consumer acknowledges and agrees that Rover carries no liability for any of the Support (its quality, effectiveness or otherwise) as provided by the MindCare Providers. The Consumers are themselves responsible for their decisions to listen to or carry out the Support and shall not hold Rover liable for any consequences c.q. damages which may result from a Consumer\u2019s decision to comply or not comply with the Support."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "10.3. The cumulative liability of Rover for any claim (or the sum of multiple claims) brought by a Consumer, under its agreement with Rover, under law or in equity, shall be limited to the amount which equals the total price paid by a Consumer for its purchases on the Website during a period of one year preceding the bringing of the claim. This limitation of liability applies regardless on which grounds Rover is being held liable by the Consumer, whether it\u2019s a claim (or multiple claims) for damages, performance or rescission or a combination thereof or on any other grounds. This limitation of liability shall not apply in case the Consumer\u2019s losses or damages are the result of wilful actions or gross negligence on the part of Rover."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "10.4. Rover does not in any way guarantee that your obtainment of Support from our MindCare Providers will lead to any improvement in your general wellbeing or a solution to any issue you may be having. Though the MindCare Providers\u2019 efforts are directed towards improving your general wellbeing and helping you solve or cope with the issues you have presented to them, Rover does not make any guarantees in that regard."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "10.5. Rover shall carry no liability for any damages you may suffer due to a lack of access to your MindCare Provider caused by a technical failure associated with the platform. In case of such technical failure, Rover shall apply efforts to, as soon as possible, resolve the issue and ensure access."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "10.6. Rover shall, under no circumstance, carry any liability for any indirect or consequential losses or damages suffered by Consumers."
        })]
      })]
    }), _jsxs(TextPageSectionStyled, {
      children: [_jsx(TextPageSectionTitleStyled, {
        children: "11. The Right of Withdrawal"
      }), _jsx(TextPageOrderedListStyled, {
        children: _jsxs(TextPageOrderedListItemStyled, {
          children: ["11.1. A right of withdrawal shall apply to your purchase of coins. For more information on the Right of Withdrawal in relation to the purchase of Chats, please click", ' ', _jsx(TextPageLinkExternalStyled, {
            href: rightOfWithdrawalUrl,
            children: "here"
          }), "."]
        })
      })]
    }), _jsxs(TextPageSectionStyled, {
      children: [_jsx(TextPageSectionTitleStyled, {
        children: "12. Complaints"
      }), _jsx(TextPageOrderedListStyled, {
        children: _jsxs(TextPageOrderedListItemStyled, {
          children: ["12.1. Consumers can address any complaints they may have to Rover either per e-mail to", ' ', _jsx(TextPageLinkExternalStyled, {
            href: `mailto:${supportEmail}`,
            children: supportEmail
          }), ' ', "or per telephone by dialling", ' ', _jsx(TextPageMarkerStyled, {
            children: "+972-3-5277233"
          }), ". Rover undertakes to constructively deal with any complaint and shall do so within a reasonable time after receiving such complaint."]
        })
      })]
    }), _jsxs(TextPageSectionStyled, {
      children: [_jsx(TextPageSectionTitleStyled, {
        id: TERM_FOR_EU_RESIDENT.NOTIFICATION_OF_ILLEGALITY,
        children: "13. Notification of illegality"
      }), _jsxs(TextPageOrderedListStyled, {
        children: [_jsx(TextPageOrderedListItemStyled, {
          children: "13.1. Should you encounter any content on the Website or any content or communications provided or shared with you by MindCare Providers, which you deem to be unlawful content, you can notify us of this finding and we shall take all measures imposed on us under law to deal with your notification, including the removal of the alleged illegal content or cancellation of the MindCare Provider\u2019s participation on our platform."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "13.2. Your notification referred to in clause 13.1 must contain (i) the reasons why you deem certain content or a communication to be unlawful as well as a specific description of the unlawful content or, in case its content is placed on the Website, the url to the site so we can see it for ourselves; (ii) a statement confirming your belief that the information and allegations you submit are complete and accurate; as well as (iii) your name and e-mail address."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "13.3. Upon receipt of your notification, we shall send you a confirmation of our duly receipt and we shall inform you of the decisions we make further to your notification."
        })]
      })]
    }), _jsxs(TextPageSectionStyled, {
      children: [_jsx(TextPageSectionTitleStyled, {
        children: "14. Termination or suspension & amendment or waiver"
      }), _jsxs(TextPageOrderedListStyled, {
        children: [_jsx(TextPageOrderedListItemStyled, {
          children: "14.1. The agreement with the Consumers, consisting of (partly) these Terms, shall commence on the date on which a consumer has registered and obtained an account on the Website. It shall, in principle, remain in force for as long as the Consumer has not terminated the same."
        }), _jsxs(TextPageOrderedListItemStyled, {
          children: ["14.2. The Consumer may at any time terminate the Agreement with immediate effect. Any termination, regardless of the grounds, will result in the Consumer forfeiting any unused coins", ' ', _jsx(TextPageMarkerStyled, {
            children: "within 90 days"
          }), " after termination. Unused coins will thus not be refunded in any way upon termination but shall instead be cancelled after the mentioned period."]
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "14.3. Rover shall remain entitled to either suspend or terminate the Agreement at any time with immediate effect in case of any violation by a Consumer of any of its obligations under this Agreement or under law as well as for any other reason which, for grounds of reputational harm or otherwise, renders it unreasonable to expect Rover to continue to grant the Consumer access to its services. Any suspension or termination will lead to an immediate cancellation of any unused Chats."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "14.4. Rover shall be entitled to unilaterally amend these Terms. It shall notify you of any amendment by sending a notification e-mail to you. Subject to any legal or operational requirement justifying a shorter term for entry into force, the amendment shall take effect after a period of ten working days from the date on which the initial notification e-mail was sent unless you reject the amendment before the termination of the applied term. In case of a rejection, you shall be deemed to have terminated your Agreement with effect from the date on which the amendment shall enter into force. Any amendments to this Agreement will be included in the most current version of the Terms as available on the Website."
        })]
      })]
    }), _jsxs(TextPageSectionStyled, {
      children: [_jsx(TextPageSectionTitleStyled, {
        children: "15. Marketing and Privacy"
      }), _jsx(TextPageOrderedListStyled, {
        children: _jsxs(TextPageOrderedListItemStyled, {
          children: ["15.1. Any and all treatment of personal data you share on our platform as well any use of your personal data for marketing purposes shall take place in accordance with applicable data protection legislation as set out in our Privacy Policy which you can find", ' ', _jsx(TextPageLinkExternalStyled, {
            href: privacyPolicyEnUrl,
            children: "here"
          }), "."]
        })
      })]
    }), _jsxs(TextPageSectionStyled, {
      children: [_jsx(TextPageSectionTitleStyled, {
        children: "16. Applicable laws and competent court"
      }), _jsxs(TextPageOrderedListStyled, {
        children: [_jsx(TextPageOrderedListItemStyled, {
          children: "16.1. All agreements with Consumers and any non-contractual obligations arising out of it or in connection with said agreements shall be governed by and shall be construed in accordance with the laws of Israel. However, this choice of law clause shall not affect the applicability of mandatory provisions of the Consumer\u2019s home country insofar these are more protective."
        }), _jsx(TextPageOrderedListItemStyled, {
          children: "16.2. Subject to mandatory law of the Consumer\u2019s country of residence regarding the competent court, any disputes arising out of or in connection with agreements between the Consumer and Rover, including regarding the existence or validity of said agreements, and any non-contractual obligations arising out of or in connection with said agreements, are subject to the jurisdiction of the competent court in Israel."
        })]
      })]
    })]
  });
};